.cluster-header {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 4.5em;
    font-weight: bold;
}

.cluster-description {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 40px;
    padding: 0 20px;
}

.knowledge-header {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
    margin-left: 40px;
}

.knowledge-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1440px;
    margin: 0 auto;
    padding-top: 20px;
}

.card {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    transition: transform 0.2s;
    position: relative;
}

.card:hover {
    transform: translateY(-10px);
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    flex: 1;
}

.pricing-button {
    margin-top: 16px;
    background: transparent;
    border: 2px solid #007bff;
    padding: 6px;
    font-size: 16px;
    font-weight: bold;
    color: #007bff;
    transition: background 0.3s, color 0.3s;
}

.remove-button {
    margin-top: 16px;
    background: transparent;
    border: 2px solid red;
    padding: 6px;
    font-size: 16px;
    font-weight: bold;
    color: red;
    transition: background 0.3s, color 0.3s;
}

.card-title {
    font-size: 1em;
    margin-bottom: 15px;
}

.card-text {
    font-size: 1em;
    color: #666;
    margin-bottom: 15px;
    flex-grow: 1;
}

.courses-header {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 10px;
}

.course-title {
    font-size: 14px;
    color: #004080;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.list-group-item {
    padding: 10px 0px;
    border-bottom: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.button-bar-container {
    position: relative;
    margin-bottom: 20px;
    padding: 0 50px; /* Ensure space for the scroll buttons */
}

.scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 1;
}

.scroll-button-left {
    left: 0;
}

.scroll-button-right {
    right: 0;
}

.button-bar {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    justify-content: flex-start;
    background: linear-gradient(45deg, #6a11cb, #2575fc);

    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    scrollbar-width: none; /* For Firefox */
}

.button-bar::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.button-bar .btn {
    display: inline-block;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px 20px;
    backdrop-filter: blur(10px);
    transition: background-color 0.3s, transform 0.3s;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    white-space: nowrap; /* Prevent button text from wrapping */
}

.button-bar .btn:hover {
    background-color: rgba(255, 255, 255, 0.4);
    transform: scale(1.05);
}

.btn-active {
    background-color: rgba(255, 255, 255, 0.4) !important;
    transform: scale(1.05) !important;
}

.empty-card {
    display: none;
}

.button-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.checkbox-container {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.checkbox-label {
    font-size: 14px;
    color: #004080;
}

.knowledge-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 0 20px;
    max-width: 1440px;
    margin: 0 auto;
    padding-top: 20px;
}
